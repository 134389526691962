<template>
  <v-select
    class="select-frequency"
    :value="value"
    :items="items"
    :placeholder="placeholder"
    :loading="loading"
    :disabled="disabled"
    item-text="text"
    item-value="value"
    v-bind="$attrs"
    @input="onChange"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Bookable Type
 * ==================================================================================
 **/
import { BLOCK_TIME_FREQUENCIES } from '@/utils/enums/BookableType'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: 'Select Frequency',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    preSelect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    items() {
      return [...BLOCK_TIME_FREQUENCIES]
    },
  },

  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
