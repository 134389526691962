<template>
  <div :style="{ width: '100%' }">
    <div class="d-flex mb-2" v-if="hasPermissions">
      <label class="text-field-label">Block Time Slots</label>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="appbar__btn"
        height="35px"
        @click="createItem()"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add Block
      </v-btn>
    </div>
    <div
      id="block-time-slot-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="blockItems"
        :options.sync="options"
        :loading="loading"
        :items-per-page="10"
        :server-items-length="blockTimeSlotMeta.total"
        :footer-props="{
          'items-per-page-options': [10],
        }"
        @update:options="fetchBlockTimeSlotList"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
      >
        <template v-slot:item.frequency="{ item }">
          {{ frequencyName(item.frequency) }}
        </template>
        <template v-slot:item.start_date="{ item }">
          {{ formatDate(item.start_date, item.start_time) }}
        </template>
        <template v-slot:item.end_date="{ item }">
          {{ formatDate(item.end_date, item.end_time) }}
        </template>
        <template v-slot:item.start="{ item }">
          {{ formatTime(item.start_date, item.start_time) }}
        </template>
        <template v-slot:item.end="{ item }">
          {{ formatTime(item.end_date, item.end_time) }}
        </template>
        <template v-slot:item.actions="{ item }" v-if="hasPermissions">
          <template v-if="!item?.event_time_slot_id">
            <div class="d-flex">
              <v-icon class="me-2" size="default" @click="editItem(item)">{{
                icons.edit
              }}</v-icon>
              <v-icon size="default" @click="deleteItem(item)">{{
                icons.delete
              }}</v-icon>
            </div>
          </template>
        </template>
      </v-data-table>
      <v-btn
        variant="text"
        class="show-hide-slots-btn"
        @click="toggleShowHidePastSlots"
        :loading="loading"
      >
        <v-icon>{{ showAllSlots ? icons.eyesOn : icons.eyesOff }}</v-icon>
        <span class="primary--text ml-2">Show/Hide Past Slots</span>
      </v-btn>
    </div>

    <v-dialog v-model="dialogBlockSlotTime" max-width="70%">
      <v-form
        ref="formCreateBlockTimeSlot"
        class="bookable-block-time-slot-form__form"
        @submit.prevent="submitCreate"
        @click:outside="cancelDialog"
      >
        <input type="hidden" :value="formBlockTimeSlot.id" name="id" />
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >{{ formBlockTimeSlot.id ? 'Edit' : 'New' }} Block</span
            >
          </v-card-title>
          <v-card-text class="pb-0">
            <v-container class="pb-0 block-form-container">
              <v-row>
                <v-col cols="6" sm="6" md="2">
                  <v-text-field
                    flat
                    solo
                    placeholder="Description"
                    v-model="formBlockTimeSlot.description"
                    :loading="formBlockTimeSlot.$busy"
                    :disabled="formBlockTimeSlot.$busy"
                    :error-messages="formBlockTimeSlot.$getError('description')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="2">
                  <SelectFrequencyBlockTime
                    flat
                    solo
                    required
                    v-model="formBlockTimeSlot.frequency"
                    :error-messages="formBlockTimeSlot.$getError('frequency')"
                    :loading="formBlockTimeSlot.$busy"
                    :disabled="formBlockTimeSlot.$busy"
                    @input="changeFrequency"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="2">
                  <DatetimePicker
                    flat
                    solo
                    v-model="formBlockTimeSlot.start_date"
                    type="date"
                    placeholder="Start Date"
                    hide-details
                    showCalendarIcon
                    dateFormat="YYYY-MM-DD"
                    :valueZone="timeZone"
                    @input="changeStartDate"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="2">
                  <DatetimePicker
                    flat
                    solo
                    v-model="formBlockTimeSlot.end_date"
                    type="date"
                    placeholder="End Date"
                    hide-details
                    showCalendarIcon
                    dateFormat="YYYY-MM-DD"
                    :valueZone="timeZone"
                    @input="changeEndDate"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="2">
                  <DatetimePicker
                    flat
                    solo
                    v-model="formBlockTimeSlot.start_time"
                    type="time"
                    placeholder="Start Time"
                    hide-details
                    :valueZone="timeZone"
                    :minuteStep="5"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="2">
                  <DatetimePicker
                    flat
                    solo
                    v-model="formBlockTimeSlot.end_time"
                    type="time"
                    placeholder="End Time"
                    hide-details
                    :valueZone="timeZone"
                    :minuteStep="5"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!isValidTime">
                <v-col>
                  <div class="red--text">
                    The end date time should be after start date time.
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-5 pt-0">
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialog()"
            >
              CANCEL
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              name="submitCreateBlockSlotItem"
              :loading="formBlockTimeSlot.$busy"
              :disabled="disabledCreate || !isValidTime"
              @click="submitCreate"
            >
              SAVE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogDeleteBlockSlotTime" max-width="600px">
      <v-form
        ref="formDeleteBlockTimeSlot"
        class="faq-form__form"
        @submit.prevent="submitDeleteBlockTimeSlot"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >Are you sure to delete this block time slot?</span
            >
          </v-card-title>
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelDialogDelete()">
              NO
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitDeleteBlockTimeSlot"
              name="submitDeleteBlockTimeSlot"
            >
              YES
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="showError" :color="'red'" :timeout="-1" right>
      {{ errorMessage }}
      <v-btn text @click.prevent="setSnackbarVisibility(false)"
        >View Details</v-btn
      >
    </v-snackbar>
    <template v-if="openBookingList">
      <BookableBookingListModal
        :open="openBookingList"
        :bookings="bookingList"
        @cancel="toggleBookingListModal"
        @input="toggleBookingListModal"
      />
    </template>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Bookable Booking Schedule Type Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import { mdiPencil, mdiDelete, mdiPlus, mdiEyeOff, mdiEye } from '@mdi/js'
import infiniteScroll from 'vue-infinite-scroll'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import DatetimePicker from '@/components/fields/DatetimePicker'
import {
  dateOnlyFormat,
  dateFormat,
  getLocalTimeByUTC,
  startOfDayUTC,
  getOnlyHMSByUTC,
  getTimeZoneLocal,
  covertUTCToTimezone,
} from '@/utils/date'
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import BookableBookingListModal from './BookableBookingListModal'
import Booking from '@/models/Booking'
import { BLOCK_TIME_FREQUENCIES } from '@/utils/enums/BookableType'
import SelectFrequencyBlockTime from '@/components/fields/SelectFrequencyBlockTime'

export default {
  components: {
    BookableBookingListModal,
    DatetimePicker,
    SelectFrequencyBlockTime,
  },

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  props: {
    form: {
      type: Object,
      default: () => {
        return null
      },
    },
    hasPermissions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const initForm = {
      id: '',
      frequency: '',
      description: '',
      start_date: '',
      start_time: '',
      end_date: '',
      end_time: '',
    }

    return {
      defaultFilter: 'name',
      sortOrder: false,
      icons: {
        edit: mdiPencil,
        delete: mdiDelete,
        add: mdiPlus,
        eyesOff: mdiEyeOff,
        eyesOn: mdiEye,
      },
      headers: [
        {
          text: 'Frequency',
          value: 'frequency',
          sortable: false,
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false,
        },
        {
          text: 'Start Date',
          value: 'start_date',
          sortable: false,
        },
        {
          text: 'End Date',
          value: 'end_date',
          sortable: false,
        },
        {
          text: 'Start',
          value: 'start',
          sortable: false,
        },
        {
          text: 'End',
          value: 'end',
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      actions: [
        {
          value: 'edit',
          label: <v-icon class="mr-2">{{ mdiPencil }}</v-icon>,
        },
        {
          value: 'delete',
          label: <v-icon class="mr-2">{{ mdiDelete }}</v-icon>,
        },
      ],
      loading: false,
      blockItems: [],
      totalItems: 10,
      selectedItem: null,
      dialogBlockSlotTime: false,
      dialogDeleteBlockSlotTime: false,
      initForm,
      formBlockTimeSlot: new Form(initForm),
      options: {},
      blockTimeSlotMeta: 0,
      showAllSlots: false,
      bookingList: [],
      openBookingList: false,
      showError: false,
      errorMessage: '',
      isValidTime: true,
    }
  },
  mounted() {
    this.fetchBlockTimeSlotList()
  },
  watch: {
    blockTimeSlots(newValue) {
      if (newValue) {
        this.blockItems = newValue
        this.totalItems = newValue.length
      }
    },
    blockTimeSlotListMeta(newValue) {
      if (newValue) {
        this.blockTimeSlotMeta = newValue
      }
    },
    formBlockTimeSlot: {
      handler(newValue, oldValue) {
        if (newValue) {
          // eslint-disable-next-line camelcase
          const { start_date, start_time, end_date, end_time } = newValue

          // eslint-disable-next-line camelcase
          if (start_date && start_time && end_date && end_time) {
            const start = this.fullDateTimezone(
              this.getOnlyDate(start_date),
              this.getOnlyTimeAndTimezone(start_time)
            )

            const end = this.fullDateTimezone(
              this.getOnlyDate(end_date),
              this.getOnlyTimeAndTimezone(end_time)
            )

            const connectStart = new Date(start)
            const connectEnd = new Date(end)

            this.isValidTime = connectEnd >= connectStart
          }
        }
      },
      deep: true,
    },
    showAllSlots() {
      this.fetchBlockTimeSlotList()
    },
  },
  computed: {
    ...mapState({
      blockTimeSlots: (state) => state.bookable.blockTimeSlotList,
      blockTimeSlotListMeta: (state) => state.bookable.blockTimeSlotListMeta,
    }),

    submitApi() {
      return this.createBlockTimeSlot
    },

    timeZone() {
      return getTimeZoneLocal()
    },

    disabledCreate() {
      const requiredFields = [
        'start_date',
        'start_time',
        'end_date',
        'end_time',
        'description',
        'frequency',
      ]

      return requiredFields.some((field) => !this.formBlockTimeSlot[field])
    },
  },
  methods: {
    ...mapActions({
      createBlockTimeSlot: 'bookable/createBlockTimeSlot',
      getBlockTimeSlotList: 'bookable/getBlockTimeSlotList',
      updateBlockTimeSlotItem: 'bookable/updateBlockTimeSlotItem',
      deleteBlockTimeSlotItem: 'bookable/deleteBlockTimeSlotItem',
    }),
    createItem() {
      this.dialogBlockSlotTime = true
      this.selectedItem = null
      this.formBlockTimeSlot = new Form(this.initForm)
    },

    frequencyName(value) {
      const frequency = BLOCK_TIME_FREQUENCIES.find(
        (item) => item.value === value
      )
      return frequency?.text || ''
    },

    changeFrequency(value) {
      if (value === 'once_off') {
        this.formBlockTimeSlot.end_date = this.formBlockTimeSlot.start_date
      }
    },

    changeEndDate(value) {
      if (this.formBlockTimeSlot.frequency === 'once_off') {
        this.formBlockTimeSlot.start_date = value
      }
    },

    changeStartDate(value) {
      if (this.formBlockTimeSlot.frequency === 'once_off') {
        this.formBlockTimeSlot.end_date = value
      }
    },

    editItem(item) {
      this.dialogBlockSlotTime = true
      this.selectedItem = item
      // eslint-disable-next-line camelcase
      const { start_date, start_time, end_date, end_time } = item
      const connectStartDateTime = covertUTCToTimezone(
        this.connectDateTime(start_date, start_time)
      )
      const connectEndDateTime = covertUTCToTimezone(
        this.connectDateTime(end_date, end_time)
      )

      this.formBlockTimeSlot = new Form({
        ...item,
        start_date: connectStartDateTime,
        start_time: connectStartDateTime,
        end_date: connectEndDateTime,
        end_time: connectEndDateTime,
      })
    },

    connectDateTime(date, time) {
      return (
        dateFormat(date, DATETIME_FORMAT.dateFormatYMD) +
        'T' +
        // eslint-disable-next-line camelcase
        time +
        '.000000Z'
      )
    },

    formatDateString(dateString) {
      const date = new Date(dateString)
      const isoString = date.toISOString()
      return isoString.replace(/\.\d{3}Z$/, '.000Z')
    },

    formatTimeOnly(dateString) {
      const date = new Date(dateString)
      const hours = date.getUTCHours().toString().padStart(2, '0')
      const minutes = date.getUTCMinutes().toString().padStart(2, '0')

      // Combine into HH:mm format
      const time = `${hours}:${minutes}`
      return time
    },

    deleteItem(item) {
      this.dialogDeleteBlockSlotTime = true
      this.selectedItem = item
    },

    cancelDialog() {
      this.dialogBlockSlotTime = false
      this.selectedItem = null
      this.formBlockTimeSlot = new Form(this.initForm)
    },

    cancelDialogDelete() {
      this.dialogDeleteBlockSlotTime = false
      this.selectedItem = null
    },

    convertToUTC(dateString) {
      const date = new Date(dateString)
      return date.toISOString()
    },
    formatTime(date, time) {
      return dateOnlyFormat(getLocalTimeByUTC(date, time), 'HH:mm')
    },
    formatDate(date, time) {
      return dateOnlyFormat(getLocalTimeByUTC(date, time), 'ddd DD/MM/YYYY')
    },
    toggleShowHidePastSlots() {
      return (this.showAllSlots = !this.showAllSlots)
    },
    findMatchTime(itemBlock, field) {
      const findMatchItem = this.blockItems.find(
        (item) => item.id === itemBlock.id
      )
      if (findMatchItem) {
        return dateFormat(`${findMatchItem.date} ${findMatchItem[field]}`)
      }
    },

    getOnlyDate(date) {
      return date.split('T')[0]
    },

    getOnlyTimeAndTimezone(date) {
      return date.split('T')[1]
    },

    fullDateTimezone(date, timezone) {
      return date + 'T' + timezone
    },

    getFormData() {
      const { id } = this.$route.params
      // eslint-disable-next-line camelcase
      const { start_date, end_date, start_time, end_time } =
        this.formBlockTimeSlot
      const start = this.fullDateTimezone(
        this.getOnlyDate(start_date),
        this.getOnlyTimeAndTimezone(start_time)
      )

      const end = this.fullDateTimezone(
        this.getOnlyDate(end_date),
        this.getOnlyTimeAndTimezone(end_time)
      )

      const formatUTCStart = new Date(start).toISOString()
      const formatUTCEnd = new Date(end).toISOString()

      const newForm = {
        id: this.formBlockTimeSlot.id,
        frequency: this.formBlockTimeSlot.frequency,
        description: this.formBlockTimeSlot.description,
        bookable_id: id,
        start_date: startOfDayUTC(formatUTCStart),
        start_time: getOnlyHMSByUTC(formatUTCStart),
        end_date: startOfDayUTC(formatUTCEnd),
        end_time: getOnlyHMSByUTC(formatUTCEnd),
        timezone: this.timeZone,
      }

      return newForm
    },

    async submitCreate() {
      this.loading = true
      try {
        if (this.formBlockTimeSlot.id)
          await this.updateBlockTimeSlotItem(this.getFormData()).then(() => {
            this.showSnackbar('Block Time Slots details successfully updated!')
          })
        else {
          await this.createBlockTimeSlot(this.getFormData()).then((data) => {
            if (data?.success) {
              this.showSnackbar('Block Time Slots successfully created!')
            } else {
              if (data?.data.list_booking) {
                const list = data.data.list_booking.map((booking) => {
                  return new Booking(booking)
                })
                this.bookingList = list
                this.errorMessage = data.message
                this.showError = true
              } else {
                this.showSnackbar(data?.message, false)
              }
            }
          })
        }
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
        this.dialogBlockSlotTime = false
        this.fetchBlockTimeSlotList()
        this.selectedItem = null
      }
    },

    async fetchBlockTimeSlotList(options) {
      this.loading = true

      const currentPage = options?.page || 1

      try {
        await this.getBlockTimeSlotList({
          id: this.$route.params.id,
          page: currentPage,
          showAllSlotsType: this.showAllSlots ? 'include' : 'exclude',
        })
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
      }
    },

    async submitDeleteBlockTimeSlot() {
      const { id } = this.selectedItem
      this.loading = true
      try {
        await this.deleteBlockTimeSlotItem(id)
        this.showSnackbar('Delete block time slot successfully')
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
        this.dialogDeleteBlockSlotTime = false
        this.fetchBlockTimeSlotList()
      }
    },

    setSnackbarVisibility() {
      this.showError = !this.showError
      this.errorMessage = ''
      this.openBookingList = true
    },

    toggleBookingListModal() {
      this.openBookingList = !this.openBookingList
    },

    header() {
      return 'Booking List'
    },
  },
}
</script>

<style lang="scss" scoped>
.show-hide-slots-btn {
  display: flex;
  margin: 30px 0 0 auto;
}
</style>
