const BOOKABLE_TYPES = [
  {
    text: 'Service',
    value: 'service',
  },
  {
    text: 'Space',
    value: 'space',
  },
]

const BLOCK_TIME_FREQUENCIES = [
  {
    text: 'Once-off',
    value: 'once_off',
  },
  {
    text: 'Daily',
    value: 'daily',
  },
  {
    text: 'Weekly',
    value: 'weekly',
  },
  {
    text: 'Fortnightly',
    value: 'fortnightly',
  },
]
export { BOOKABLE_TYPES, BLOCK_TIME_FREQUENCIES }
